// 获取活动列表数据
const getActivityList = `/gateway/hc-serve/miniapi/activity/activityList`;
//活动详情
const activityDetail = `/gateway/hc-serve/miniapi/activity/activityDetail`;
//查询是否为志愿者
const ifVolunteerAndCommitmentLetter = `/gateway/hc-serve/miniapi/volunteer/ifVolunteerAndCommitmentLetter`;
//我的活动参与人列表
const myActivityApplicantsList = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsList`;
//活动报名
const applyActivityUrl = `/gateway/hc-serve/miniapi/activity/activityApply`;
// 申请志愿者接口
const volunteerApplication = `/gateway/hc-serve/miniapi/volunteer/volunteerApplication`;
//根据活动id和日期获取场次列表
const getScheduleListUrl = `/gateway/hc-serve/miniapi/activity/getScheduleList`;
export {
  getActivityList,
  activityDetail,
  ifVolunteerAndCommitmentLetter,
  myActivityApplicantsList,
  applyActivityUrl,
  volunteerApplication,
  getScheduleListUrl
};
